/* App.css */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.estructura {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1;
  width: 100%;
}

#carrusel,
#us,
#contact,
#curses {
  scroll-margin-top: 150px;
}

